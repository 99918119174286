import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import Button from 'react-bootstrap/Button';

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = () => {
        instance.loginRedirect(loginRequest).catch(e => {
            console.log(e);
        });
    }
    return (
        <>
            <h2>Sign in to Field Test</h2>
            <p>Use your teams account</p>
            <div className="d-grid gap-2 login_div">
                <Button variant="primary" size="lg" onClick={() => handleLogin()}>Sign in with Microsoft</Button>
            </div>
        </>
    )
}
