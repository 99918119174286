import React, { useEffect } from "react";

/**
 * Renders information about the user obtained from MS Graph
 * @param props
 */
export const ProfileData = (props) => {
    useEffect(() => {
        if (props.graphData) {
            try {
                const message = JSON.stringify({
                    userName: `${props.graphData.givenName} ${props.graphData.surname}`,
                    userEmail: props.graphData.userPrincipalName,
                    accessToken: props.accessToken
                });
                if (window.Flutter && window.Flutter.postMessage) {
                    window.Flutter.postMessage(message);
                } else {
                    console.warn("Flutter interface is not available");
                }
            } catch (error) {
                console.error("ProfileData error: ", error);
            }
        } else {
            console.warn("No graphData provided");
        }
    }, [props.graphData]); // Only run the effect when props.graphData changes

    if (!props.graphData) {
        return <p>Loading...</p>;
    }

    return (
        <>
            <div id="profile-div">
                <p><strong>First Name: </strong> {props.graphData.givenName}</p>
                <p><strong>Last Name: </strong> {props.graphData.surname}</p>
                <p><strong>Email: </strong> {props.graphData.userPrincipalName}</p>
                {/* <p><strong>Id: </strong> {props.graphData.id}</p> */}
            </div>
        </>
    );
};
