import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';
import sha256 from 'js-sha256';

// Bootstrap components
import 'bootstrap/dist/css/bootstrap.min.css';

// Polyfill for crypto.subtle.digest
if (!window.crypto.subtle) {
    window.crypto.subtle = {};
}

if (!window.crypto.subtle.digest) {
    window.crypto.subtle.digest = async function (algorithm, data) {
        return new Promise((resolve, reject) => {
            try {
                const hash = sha256.create();
                hash.update(data);
                const hashArray = new Uint8Array(hash.array());
                resolve(hashArray);
            } catch (error) {
                reject(error);
            }
        });
    };
}

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>
    </React.StrictMode>
);
