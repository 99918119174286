import React, { useState, useEffect } from 'react';

import { PageLayout } from './components/PageLayout';
import { loginRequest } from './authConfig';
import { callMsGraph } from './graph';
import { ProfileData } from './components/ProfileData';

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import './App.css';

const ProfileContent = () => {
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);
    const [accessToken, setAccessToken] = useState(null);

    function RequestProfileData() {
        // Silently acquires an access token which is then attached to a request for MS Graph data
        instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            .then((response) => {
                setAccessToken(response.accessToken);
                callMsGraph(response.accessToken).then((response) => setGraphData(response));
            });
    }

    useEffect(() => {
        RequestProfileData();
    }, []);

    return (
        <>
            {/* <h5 className="profileContent">Welcome {accounts[0].name}</h5> */}
            {graphData && accessToken ? (
                <ProfileData graphData={graphData} accessToken={accessToken} />
            ) : (
                <center>Loading Profile</center>
            )}
        </>
    );
};

const MainContent = () => {
    return (
        <div className="App">
            <AuthenticatedTemplate>
                <ProfileContent />
            </AuthenticatedTemplate>

            {/* <UnauthenticatedTemplate>
                <h5 className="card-title">Please sign-in to see your profile information.</h5>
            </UnauthenticatedTemplate> */}
        </div>
    );
};

export default function App() {
    return (
        <PageLayout>
            <MainContent />
        </PageLayout>
    );
}
